<template lang='pug'>
	.d-flex.flex-column.not-found
		.d-flex.flex-column.justify-content-center.align-items-center.not-found__content
			img.not-found__logo(src="/assets/img/current/logo-blue.svg")

			img.not-found__decor.not-found__decor--1(src="/assets/img/404/decor-1.png")
			img.not-found__decor.not-found__decor--2(src="/assets/img/404/decor-2.png")
			img.not-found__decor.not-found__decor--3(src="/assets/img/404/decor-3.png")
			img.not-found__decor.not-found__decor--4(src="/assets/img/404/decor-4.png")
			svg.not-found__decor.not-found__decor--5(xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none")
				circle(cx="75" cy="75" r="74.5" stroke="black" stroke-opacity="0.15" stroke-dasharray="20 20")

			.d-flex.flex-column.justify-content-center.align-items-center.not-found__wrap
				span.not-found__title 404
				p.not-found__subtitle {{ $t('404.subtitle') }}
				p.b1.text-grey.not-found__text(v-if="islocaleRuSystemAndIp") {{ $t('404.text') }}
				
				.d-flex.align-items-center.not-found__socials(v-if="socialsRu && islocaleRuSystemAndIp")
					a.not-found__social-link(v-for="(social, i) in socialsRu" :key="`social-${i}`" :href="social.href" target="_blank")
						img.not-found__social-image(:src="social.src" :alt="social.name")

				.btn.btn-primary.not-found__button(@click="location != 'paywall' ? $router.push('/login') : $router.push('/')") {{ $t('404.button') }}

		svg.not-found__decor.not-found__decor--6(width="385" height="298" viewBox="0 0 385 298" fill="none" xmlns="http://www.w3.org/2000/svg")
			circle(cx="192.5" cy="105.5" r="192" stroke="black" stroke-opacity="0.15" stroke-dasharray="20 20")
		svg.not-found__decor.not-found__decor--7(width="546" height="392" viewBox="0 0 546 392" fill="none" xmlns="http://www.w3.org/2000/svg")
			circle(cx="273" cy="273" r="272.5" stroke="black" stroke-opacity="0.15" stroke-dasharray="20 20")

		footer-payment.mt-md-5(:underLogoText="isMobile ? currentYear : `2020 - ${currentYear}`" :rightText="$t('h1.serviceRules3')" :switches="false")
</template>

<script>
import FooterPayment from "@/containers/FooterPayment";
import { socialsRu } from "@/constants/config";

export default {
	name: '404',
	metaInfo () { return {title: '404'}},
	components: {
		FooterPayment
	},
	created() {
		this.viewGeoIpRussia();
	},
    mounted() {
        document.body.classList.add("background-main");
    },
	computed: {
		socialsRu() {
			return socialsRu.apply(this);
		},
		currentYear() {
			return new Date(). getFullYear();
		},
		location() {
			return window.location.hostname.split('.')[0]
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.not-found {
	position: relative;
	height: 100%;

	@include respond-below(lg) {
		height: auto;
	}

	&__content {
		position: relative;
		padding: 45px 0;
		height: max-content;
		margin: auto 0;

		@include respond-below(lg) {
			height: auto;
		}
	}

	&__logo {
		display: block;
		width: 149px;
		height: 36.5px;
		margin-bottom: 32px;

		@include respond-below(sm) {
			margin-bottom: 12px;
		}
	}

	&__wrap {
		max-width: 972px;
		width: 100%;
		height: max-content;
		padding: 0 0 45px;
		border-radius: 100px;
		background: rgba(255, 255, 255, 0.30);
		box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.05);
		backdrop-filter: blur(10px);

		@include respond-below(sm) {
			border-radius: 0;
			background: none;
			box-shadow: none;
			backdrop-filter: blur(0);
		}
	}

	&__title {
		font-size: 300px;
		line-height: normal;
		background: linear-gradient(84deg, #52A7F9 19.56%, #1480FE 85.93%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@include respond-below(sm) {
			font-size: 150px;
		}
	}

	&__subtitle {
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 19px;

		@include respond-below(sm) {
			font-size: 18px;
		}
	}

	&__text {
		max-width: 623px;
		margin-bottom: 32px;
		text-align: center;
	}

	&__socials {
		@include respond-below(sm) {
			max-width: 359px;
			width: 100%;
			justify-content: space-between;
		}
	}

	&__social-link {
		width: 31px;
		min-width: 31px;
		height: 31px;
		min-height: 31px;
		transition: var(--animation-time-short);
		margin-right: 30px;
		border-radius: 50%;

		&:hover,
		&:active {
			transform: scale(1.2);
		}

		&:last-child {
			margin-right: 0;
		}

		@include respond-below(sm) {
			margin-right: 0;
		}
	}

	&__social-image {
		width: 100%;
		height: 100%;
	}

	&__button {
		width: 100%;
		max-width: 391px;
		margin-top: 30px;

		@include respond-below(sm) {
			margin-top: 50x;
		}
	}

	&__decor {
		z-index: 1;
		position: absolute;

		&--1 {
			z-index: 0;
			top: 90px;
			left: 16px;
			width: 201px;
			transform: rotate(-11.315deg);

			@include respond-below(lg) {
				z-index: 1;
				top: 12px;
				left: -32px;
				width: 120px;
				transform: rotate(-11.315deg);
			}
		}

		&--2 {
			top: 110px;
			right: 50px;
			width: 191px;
			transform: rotate(-18.759deg);

			@include respond-below(lg) {
				display: none;
			}
		}

		&--3 {
			bottom: -20px;
			left: 62px;
			width: 152px;

			@include respond-below(lg) {
				display: none;
			}
		}

		&--4 {
			bottom: -20px;
			right: 56px;
			width: 163px;
			transform: rotate(13.483deg);

			@include respond-below(lg) {
				z-index: 0;
				bottom: -28px;
				right: -12px;
				width: 100px;
			}
		}

		&--5 {
			bottom: 40px;
			right: -95px;

			@include respond-below(lg) {
				display: none;
			}
		}

		&--6 {
			z-index: -1;
			top: 0;
			right: 0;

			@include respond-below(lg) {
				display: none;
			}
		}

		&--7 {
			z-index: -1;
			bottom: 0;
			left: 0;

			@include respond-below(lg) {
				display: none;
			}
		}
	}
}
</style>
